<template>
    <div class="login">
        <img class="login_img" src="../../assets/img/login.png" alt="">

        <div class="login-cont">
            <div class="title">服务通</div>
            <div class="login-wai">
                <div class="biao">账号密码登录</div>
                <el-input v-model="code" placeholder="请输入账号" @keyup.enter.native="enterClick()"></el-input>
                <el-input type="password" v-model="passWore" placeholder="请输入密码" @keyup.enter.native="enterClick()"></el-input>
                <el-button type="primary" style="width:100%;" :disabled="code == '' || passWore == '' " @click="loginClick()">确定
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { loginApi } from '../../api/srv-store'
import Router from '../../router/index';
console.log(Router)

export default {
    data() {
        return {
            code: '',
            passWore: ''
        }
    },
    created() {
    },
    methods: {
        loginClick() {
            this.logApi()
        },
        enterClick() {
            if (this.account != '' && this.password != '') {
                this.logApi()
            }
        },
        logApi() {
            loginApi({
                account: this.code,
                password: this.passWore
            }).then(Er => {
                if (Er.return_code === 200) {
                    console.log(Er.data)
                    this.$message({
                        message: '登陆成功',
                        type: 'success'
                    });
                    localStorage.setItem('userName', Er.data.name)
                    localStorage.setItem('account', Er.data.account)
                    localStorage.setItem('brand_id', Er.data.brand_id)
                    localStorage.setItem('store_id', Er.data.store_id)
                    localStorage.setItem('Authorization', Er.data.authorization)
                    if( Er.data.menu_list &&  Er.data.menu_list.length>0){
                        let menuList = [
                            {
                                path: '/brandManage',
                                redirect: '/brandManage',
                                component: () => import('@/layouts/content'),
                                title: '服务通',
                                children: []
                            }
                        ]
                        Er.data.menu_list.map(item => {
                            menuList[0].children.push({
                                path: item.path,
                                component: () => import(item.path_url),
                                title: item.name,
                            })
                        })
                        localStorage.setItem('menu_list', JSON.stringify(menuList))
                    }else{
                        localStorage.setItem('menu_list', JSON.stringify([]))
                    }
                    console.log()
                    this.$router.push(Er.data.menu_list[0].path)
                } else {
                    this.$message(Er.return_msg)
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
.login {
  height: 100%;
  background: url(../../assets/img/log_bg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .login_img {
    position: absolute;
    top: 60px;
    left: 60px;
    height: 40px;
    width: 114px;
  }
  .login-cont {
    width: 376px;
    .title {
      text-align: center;
      color: #fff;
      font-size: 48px;
      margin-bottom: 38px;
    }
    .login-wai {
      padding: 24px 48px 34px 48px;
      border-radius: 4px;
      line-height: 60px;
      background: #ffffff;
      box-shadow: 0px 6px 12px 0px rgba(62, 86, 110, 0.06);
      border-radius: 16px;
      border: 1px solid rgba(62, 86, 110, 0.1);
      .biao {
        color: rgba(62, 86, 110, 1);
        font-size: 24px;
        font-weight: 500;
      }
    }
  }
}
</style>
